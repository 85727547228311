import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePageView } from 'utils/hooks';

import { useRouter } from '../../../apis/history';
import { updateQuizAnswers } from '../../../state/user/effects';

import { Header } from './sections/Header';
import { AppState } from 'state/types';
import { normalizeStates } from 'utils/localization';

const Landing: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const { goToQuiz } = useRouter();
  const { code, user, geolocation, quiz_answers } = useSelector(
    (state: AppState) => state.user,
  );

  usePageView({
    client_code: code,
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    email: user?.email,
  });

  const handleGenderSelection = (gender: string) => {
    dispatch(updateQuizAnswers({ gender }));

    goToQuiz();
  };

  return (
    <>
      <Header onSelect={handleGenderSelection} />
    </>
  );
});

Landing.displayName = 'Landing';

export default Landing;
